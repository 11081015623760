*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:sans-serif;
}
.swal2-icon.swal2-warning {
    border-color: #ff0000!important;
    color: #ff0000!important;
}
.swal2-title{
    color: black!important;
}
header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
    background-color:rgba(0, 0, 0, 0.42);
    z-index: 10000;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.navigaition{
    display: flex;
}
.navigaition li{
    list-style: none;
    margin: 0px 10px;
}
.navigaition li a{
    color: white;
    text-decoration: none;
    font-weight: 800;
    letter-spacing: 1px;
}
.navigaition li:hover a{
    color: red;
    font-weight: 900;
    text-decoration: none;
}
.search{
    position: relative;
    width: 300px;
    height: 40px;
}
.search input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    outline: none;
    padding: 0px 10px 0px 45px;
    backdrop-filter: blur(15px);
    border-radius: 5px;
}
.search input::placeholder{
    color: white;
}
.banner{
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 0px 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.banner .bg{
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   object-fit: cover;
}
.content{
    position: relative;
    z-index: 0;
    max-width: 550px;
}
.banner .content h4{
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
}
.banner .content h4 span{
padding: 0px 10px;
border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.banner .content h4 span:first-child{
padding-left: 0;
}
.banner .content h4 span:last-child{
    border-right: none;
}
.banner .content h4 span i{
    background-color: red;
    color: white;
    padding: 0px 8px;
    display: inline-block;
    font-style: normal;
    border-radius: 4px;
}
.banner .content p{
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5em;
    color: white;
    margin: 10px 0 10px;
}
.buttons{
    width: 200px;
    display: flex;
    justify-content: space-between;
}
.Mycarts{
    margin-top: -150px;
    background-color: #000000ed;
    box-shadow: rgb(0 0 0) 0px -10px 50px 30px;
}
.usericon{
    position: absolute;
    font-size: 40px;
    right: 0;
    cursor: pointer;
}
#usericon{
    position: absolute;
    right: 0;
    width:40px;
    height:40px;
    border: 3px solid red;
    border-radius:50%;
    cursor: pointer;
}
@media screen and (min-width:0px) and (max-width:1100px) {
    header{
        padding: 5px 20px;
        box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 54px 19px;
        background-color:rgba(0, 0, 0, 0.51);
    }
    .search input{
        padding: 0px;
        left: -50px;
    }
}
@media screen and (min-width:0px) and (max-width:900px) {
.navigaition li:nth-child(4) a{
    display: none;
}
.search{
    width: 200px;
}
.search input{
    left: -40px;
}
}
@media screen and (min-width:0px) and (max-width:650px) {
    .navigaition{
        display: none;
    }
    .search{
        width: 150px;
    }
    header{
        padding: 10px;
    }
    .banner{
        padding: 100px 20px;
        min-height: 55vh;
    }
    .Mycarts{
        margin-top: -60px;
    }
    .usericon{
        display: none;
    }
    #usericon{
        display: none;
    }
    .search input{
        left: 0;
    }
}
@media screen and (min-width:0px) and (max-width:500px) {
    .content{
        display: none;
    }
    .banner .bg{
        height: auto;
        object-fit: contain;
        top:59px;
    }
    .Mycarts{
        padding-bottom: 50px;
        margin-top: -20px;
    }
}
@media screen and (min-width:0px) and (max-width:450px) {
    .Mycarts{
        margin-top: -70px;
    }
    .banner {
        min-height: 39vh;
    }
    
    
}
@media screen and (min-width:0px) and (max-width:400px) {
    .Mycarts{
        margin-top: -40px;
    }
}
@media screen and (min-width:0px) and (max-width:360px) {
    .Mycarts{
        margin-top: -55px;
    }
}
@media screen and (min-width:0px) and (max-width:300px) {
    .Mycarts{
        margin-top: -55px;
    }
}