.mdiv{
    margin: 100px 10px 0 10px;
}
.nodata{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 15px;
}
.imgborder{
    border-radius: 5px;
    cursor: pointer;
}
#myTargetElement{
    display: flex;
    justify-content: center;
    margin: 50px;
}
.videodiv {
    border: 1px solid #ff00001f;
    border-radius: 5px;
}
.aspect-ratio-16x9 {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
}
.aspect-ratio-16x9 > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
@media screen and (min-width:0px) and (max-width:900px){
    .nodata{
        grid-template-columns: repeat(4,1fr);
        gap: 15px;
    }
}
@media screen and (min-width:0px) and (max-width:600px){
    .nodata{
        grid-template-columns: repeat(3,1fr);
        gap: 10px;
    }
}
@media screen and (min-width:0px) and (max-width:500px){
    .nodata{
        grid-template-columns: repeat(2,1fr);
        gap: 5px;
    }
    .mdiv{
        margin: 100px 5px 0 5px;
    }
}
@media screen and (min-width:0px) and (max-width:400px){
    .nodata{
        grid-template-columns: repeat(1,1fr);
        gap: 0px;
    }
    .mdiv{
        margin: 100px 0px 0 0px;
    }
    .imgborder{
        padding: 10px 0;
    }
}